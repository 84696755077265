
import { UrlState } from '@shared/overlays/disposal-reporting-present-overlay/DisposalReportingPresentOverlay.context';
import { getQueriesAsSearchNew } from '@shared/utils/common';

const disposalsManage = {
  rootPath: '/disposals/:id',
  root: (disposalId: string) => disposalsManage.rootPath.replace(':id', disposalId),

  summary: '/summary',
  viewings: '/viewings',
  reporting: '/reporting',
  //viewing_insights: '/viewing-insights', //TODO find out if this page is required
  insights: '/insights',
} as const;

export type DisposalManagePage = ValueOf<typeof disposalsManage>;

const ROUTES = {
  initial: '/',
  logOut: '/logout',
  defaultRedirect: '/disposals',

  organisation: {
    disposals: {
      root: '/disposals',
      manage: disposalsManage,
    },
    viewings: {
      root: '/viewings',
    },
  },
  marketplace: {
    marketplace_members: '/marketplace-members',
    marketplace_events: '/marketplace-events',
  },

  present: {
    disposalReportPath: '/present/:id/report',
    disposalReport: (id: string, queryParams?: UrlState) => {
      const basePath = ROUTES.present.disposalReportPath.replace(':id', id);

      if (!queryParams) {
        return basePath;
      }

      return `${basePath}?${getQueriesAsSearchNew(queryParams)}`;
    },
  },
};

export default ROUTES;
